<template>
  <header-chat @closed="onHeaderClose" />
  <div
    ref="chat_box"
    @wheel="onChatScroll"
    class="flex flex-col space-y-4 p-3 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch chat-box-fixed-width"
  >
    <audio
      :src="require('@/assets/sound/message_receive.mp3')"
      ref="receivedSoundPlayer"
      id="receiveMessage"
      class="hidden"
    />
    <div
      class="chat-message"
      v-for="message in history"
      :key="message.timestamp"
    >
      <div class="flex items-end" :class="message.me ? 'justify-end' : ''">
        <div
          class="flex flex-col space-y-2 text-xs max-w-xs mx-2"
          :class="message.me ? 'items-end order-1' : 'items-start order-2'"
        >
          <div>
            <span
              :class="
                message.me
                  ? 'bg-bl out-message-container message-container text-white'
                  : 'bg-gray-300 message-container text-gray-600'
              "
              class="px-4 py-2 rounded-lg inline-block rounded-br-none"
              >{{ message.message.message }}</span
            >
          </div>
          <div v-for="block in message.block" :key="block.timestamp">
            <span
              :class="
                message.me
                  ? 'out-message-container message-container text-white'
                  : 'bg-gray-300 message-container text-gray-600'
              "
              class="px-4 py-2 rounded-lg inline-block"
              >{{ block.message.message }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-2 sm:mb-0 input-container">
    <input-chat />
  </div>
</template>
<script>
import { defineComponent, onMounted, computed, ref, onUpdated } from "vue";
import { useStore } from "@/store";
import InputChat from "./InputChat.vue";
import HeaderChat from "./HeaderChat.vue";

let lastMessagesLength = 0;

export default defineComponent({
  components: { InputChat, HeaderChat },
  emits: ["closed"],
  setup(props, { emit }) {
    const store = useStore();
    const chat_box = ref(null);
    const history = computed(() => store.getters["chat/history"]);
    const receivedSoundPlayer = ref(null);

    onUpdated(() => {
      if (!history.value || history.value.length === 0) return;

      let block = history.value[history.value.length - 1].block;

      if (!block) return;

      let blockLength = block.length;

      if (blockLength !== lastMessagesLength) {
        const me = history.value[history.value.length - 1].me;

        if (!me) {
          let player = receivedSoundPlayer.value;
          player.load();
          player.play();
        }

        lastMessagesLength = blockLength;

        chat_box.value.scrollTo({
          top: chat_box.value.scrollHeight,
          behavior: "smooth",
        });
      }
    });

    const onHeaderClose = () => {
      emit("closed");
    };

    const onChatScroll = (event) => {
      var el = chat_box.value;
      //Check if div scroll is on top
      if (el.scrollTop == 0) {
        store.dispatch("chat/loadBefore");
        if (event.deltaY < 0) {
          event.preventDefault();
          return false;
        }
      } else if (el.offsetHeight + el.scrollTop >= el.scrollHeight) {
        if (event.deltaY > 0) {
          event.preventDefault();
          return false;
        }
      }
    };

    onMounted(() => {
      chat_box.value.scrollTo({
        top: chat_box.value.scrollHeight,
        behavior: "smooth",
      });
    });
    return {
      history,
      onChatScroll,
      chat_box,
      receivedSoundPlayer,
      onHeaderClose,
    };
  },
});
</script>

<style scoped lang="scss">
@media only screen and (max-width: 600px) {
  .chat-box-fixed-width {
    width: 100% !important;
    height: 100%;
    max-height: 100% !important;
  }
}

.input-container {
  box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.05) !important;
}

.message-container {
  border-radius: 5px;
  font-size: 14px;
  padding: 12px 15px;
  overflow-wrap: break-word;
  word-break: break-word;
  text-align: left;
}

.out-message-container {
  background-color: #101921;
}

.chat-box-fixed-width {
  width: 350px;
  min-height: 250px;
  max-height: 475px;
}

.scrollbar-w-2::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

.scrollbar-track-blue-lighter::-webkit-scrollbar-track {
  --bg-opacity: 1;
  background-color: #53535333;
  border-radius: 6px;
}

.scrollbar-thumb-blue::-webkit-scrollbar-thumb {
  --bg-opacity: 1;
  background-color: #302f2f;
  border-radius: 6px;
}

.scrollbar-thumb-rounded::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
}
</style>

