<template>
    <svg viewBox="0 0 512 512" width="18" xmlns="http://www.w3.org/2000/svg">
        <g id="_03_Login" data-name="03 Login">
            <path d="m256 512a25 25 0 0 1 -25-25v-462a25 25 0 0 1 50 0v462a25 25 0 0 1 -25 25z" />
            <path d="m487 281h-462a25 25 0 0 1 0-50h462a25 25 0 0 1 0 50z" />
        </g>
    </svg>
</template>
<script>
export default {
    name: 'Plus',
    data() {
        return {}
    },
    props: {
        fill: {
            default: '--color-primary'
        }
    }
}
</script>