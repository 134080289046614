import axios from "axios";
import * as types from "./mutation-types";
import {
  connect,
  handleTextMessage,
  beforeFetchNumber,
  useChannels
} from "@/plugins/chat/index";
import { reactive } from "vue";

let isLoading = false;
const state = () => {
  return {
    clients: [],
    history: reactive([]),
    currentChannel: null,
    currentClient: null,
    channelState: {},
    newMessageDisplay: false
  };
};

function isJson(item) {
  item = typeof item !== "string" ? JSON.stringify(item) : item;

  try {
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }

  if (typeof item === "object" && item !== null) {
    return true;
  }

  return false;
}

function getWeekDayPT(pos) {
  if (pos === 0) return "Domingo";
  if (pos === 1) return "Segunda";
  if (pos === 2) return "Terça";
  if (pos === 3) return "Quarta";
  if (pos === 4) return "Quinta";
  if (pos === 5) return "Sexta";

  return "Sábado";
}

// getters
const getters = {
  newMessageDisplay(state) {
    return state.newMessageDisplay
  },
  clients(state) {
    return state.clients;
  },
  isConnected: (state) => (key = "visitant") => { 
    if(key in state.channelState)
      return state.channelState[key];
    return false;
  },
  history(state) {
    return state.history;
  },
  currentChannel(state) {
    return state.currentChannel;
  },
  currentClient(state) {
    return state.currentClient;
  },
  schedule(state) {
    return state.schedule;
  },
  initialMessage(state) {
    return state.initialMessage;
  }
};

// actions
const actions = {
  newMessageDisplay({ commit }, data) {
    commit(types.SET_NEW_MESSAGE_DISPLAY, data);
  },
  async register({ commit }, info = null) {
    /*
     *  Check if we have a token in our localStorage
     *  If not lets request one with our email and name
     *  After we have a valid token, lets init chat
     */
    let token = localStorage.getItem("token") || null;
    let channel = JSON.parse(localStorage.getItem("channel") || "{}");
    let client = localStorage.getItem("client") || null;
    if (!token) {
      if (!info) {
        commit(types.SET_IS_CONNECTED, false);
        return;
      }
      const channelsSDK = useChannels();
      const response = await axios.post(
        (channelsSDK._isSecure ? "https" : "http") +
          channelsSDK._url +
          "/landing",
        {
          name: info.name,
          email: info.email
        }
      );
      token = response.data.token;
      channel = response.data.channel;
      client = response.data.clientID;
    }

    commit(types.SET_CURRENT_CLIENT, client);
    connect(channel, token);
  },
  async registerWithMessage({ commit }, info = null) {
    /*
     *  Check if we have a token in our localStorage
     *  If not lets request one with our email and name
     *  After we have a valid token, lets init chat
     */
    let token = localStorage.getItem("token") || null;
    let channel = JSON.parse(localStorage.getItem("channel") || "{}");
    let client = localStorage.getItem("client") || null;
    if (!token) {
      if (!info) {
        commit(types.SET_IS_CONNECTED, false);
        return;
      }
      const channelsSDK = useChannels();
      const response = await axios.post(
        (channelsSDK._isSecure ? "https" : "http") +
          channelsSDK._url +
          "/landing",
        {
          name: info.name,
          email: info.email
        }
      );
      token = response.data.token;
      channel = response.data.channel;
      client = response.data.clientID;
    }

    commit(types.SET_CURRENT_CLIENT_WITH_INITIAL_MESSAGE, {
      client,
      message: info.message
    });
    connect(channel, token);
  },
  async getSchedule({ commit, state }) {
    const channelsSDK = useChannels();
    const response = await axios.get(
      (channelsSDK._isSecure ? "https" : "http") +
        channelsSDK._url +
        "/schedule"
    );

    if (response.status === 200) {
      let schedule = response.data;

      schedule.startWeekDay = getWeekDayPT(schedule.schedule.dayStart);
      schedule.endWeekDay = getWeekDayPT(schedule.schedule.dayEnd);

      let start = schedule.schedule.start;

      if (start < 9) {
        schedule.formattedStart = "0" + start + ":00";
      } else {
        schedule.formattedStart = start + ":00";
      }

      let end = schedule.schedule.end;

      if (end < 9) {
        schedule.formattedEnd = "0" + end + ":00";
      } else {
        schedule.formattedEnd = end + ":00";
      }

      commit(types.SET_SCHEDULE, schedule);
    }
  },
  loadBefore({ commit, state }) {
    if (state.history.length > 0 && !isLoading) {
      isLoading = true;
      const firstMessage = state.history[0];
      const channelsSDK = useChannels();
      channelsSDK
        .fetchLastChannelEventsBefore(
          state.currentChannel,
          beforeFetchNumber,
          firstMessage.timestamp
        )
        .then(response => {
          response.sort(function(a, b) {
            return b.timestamp - a.timestamp;
          });
          response
            .filter(e => e.eventType === "text_message")
            .forEach(element => {
              if (
                element.timestamp === firstMessage.timestamp &&
                element.payload === firstMessage.message
              )
                return;

              let message = state.history.length > 0 ? state.history[0] : null;
              const textMessage = handleTextMessage(
                { data: element, message: message },
                "before"
              );
              textMessage.index = 0;

              if (textMessage.mode == "update") {
                commit(types.SET_ON_TEXT_MESSAGE_UPDATE, textMessage);
              } else if (textMessage.mode == "push") {
                commit(types.SET_ON_TEXT_MESSAGE_BEFORE, textMessage.message);
              }
            });
          if (response.length == beforeFetchNumber) isLoading = false;
        });
    }
  },
  currentChannel({ dispatch, commit }, data) {
    commit(types.SET_CURRENT_CHANNEL, data);
    const channelsSDK = useChannels();
    channelsSDK.fetchLastChannelEvents(data.id, 15).then(response => {
      response
        .filter(e => e.eventType == "text_message")
        .forEach(element => {
          dispatch("newMessage", element);
        });

      const lastMessage = response[response.length - 1];

      if (lastMessage && lastMessage.payload) {
        const channel = channelsSDK.getChannel(lastMessage.channelID);

        if (isJson(lastMessage.payload)) {
          channel.publish(
            "read_message",
            JSON.parse(lastMessage.payload).id,
            () => {}
          );
        } else {
          channel.publish("read_message", lastMessage.payload, () => {});
        }
      }
    })
    .catch(status => {
      localStorage.removeItem("token");
      dispatch("register", {
        email: "",
        phone: "",
      })
    });
  },
  newMessage({ commit, state }, data) {
    let lastMessage =
      state.history.length > 0 ? state.history[state.history.length - 1] : null;
    const response = handleTextMessage({ data: data, message: lastMessage });
    response.index = state.history.length - 1;

    if (response.mode == "update") {
      commit(types.SET_ON_TEXT_MESSAGE_UPDATE, response);
    } else if (response.mode == "push") {
      commit(types.SET_ON_TEXT_MESSAGE, response.message);
    }
  },
  onEvent({ dispatch, state }, data) {
    if (data.eventType == "text_message") {
      dispatch("newMessage", data);
    }
  },
  onConnect({ commit }, data) {
    commit(types.SET_IS_CONNECTED, data);
  }
};

// mutations
const mutations = {
  [types.SET_NEW_MESSAGE_DISPLAY](state, value) {
    state.newMessageDisplay = value;
  },
  [types.SET_IS_CONNECTED](state, value) {
    state.channelState[value.key] = value.value
  },
  [types.SET_CURRENT_CLIENT](state, value) {
    state.currentClient = value;
    localStorage.setItem("client", value);
  },
  [types.SET_CURRENT_CLIENT_WITH_INITIAL_MESSAGE](state, value) {
    state.currentClient = value.client;
    state.initialMessage = value.message;
    localStorage.setItem("client", value.client);
  },
  [types.SET_ON_TEXT_MESSAGE_UPDATE](state, value) {
    state.history[value.index].block = value.message.block;
    state.history[value.index].message = value.message.message;
  },
  [types.SET_ON_TEXT_MESSAGE](state, value) {
    state.history.push(value);
  },
  [types.SET_ON_TEXT_MESSAGE_BEFORE](state, value) {
    state.history.splice(0, 0, value);
  },
  [types.SET_CURRENT_CHANNEL](state, channel) {
    state.currentChannel = channel.id;
    localStorage.setItem("channel", JSON.stringify(channel));
  },
  [types.SET_SCHEDULE](state, schedule) {
    state.schedule = schedule;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
