<template>
    <div v-if="visible" id="chat_layout" class="fixed z-20 flex-col flex bottom-0 mr-8 mb-5 right-0">
        <div v-if="(open && isConnected) || (open && schedule && schedule.available)" class="flex-1 justify-between bg-white flex flex-col chat-floating-container" style="height: 600px; margin-bottom: 10px">
            <connected-chat @closed="toogleChat" />
        </div>
        <div v-else-if="open ">
            <initialize-chat @closed="toogleChat" />
        </div>
        <button v-on:click="toogleChat" class="ml-auto p-0 w-16 h-16 bg rounded-full bg-gray-900 hover:bg-gray-700 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none chat-toggle">
            <Chat v-if="!open" />
            <Close v-else />
            <span v-if="newMessageDisplay" class="ant-badge ant-badge-not-a-wrapper user-list-item-badge">
              <sup data-show="true" class="ant-scroll-number ant-badge-count" title="0" style="background-color: #b91c1c; color: transparent">0</sup>
            </span>
        </button>
    </div>
</template>
<script>
let firstOpen = true
import { defineComponent, onMounted, computed, ref, watch, onUpdated } from 'vue'
import { useStore } from '@/store'
import InitializeChat from './InitializeChat.vue'
import ConnectedChat from './ConnectedChat.vue'
import Chat from '@/components/Icons/Chat.vue'
import Close from '@/components/Icons/Close.vue'
import { useChannels } from '@/plugins/chat/index'

window.chatOpen = false
window.lastMessage = ''

export default defineComponent({
    components: { InitializeChat, ConnectedChat, Chat, Close },
    setup() {
        const store = useStore()
        const isConnected = computed(() => store.getters['chat/isConnected']("visitant"))
        const schedule = computed(() => store.getters['chat/schedule'])
        const newMessageDisplay = computed(() => store.getters['chat/newMessageDisplay'])
        const visible = ref(false)
        const open = ref(false)
        const channelID = computed(() => store.getters['chat/currentChannel'])

        const channelsSDK = useChannels()

        watch(schedule, (newValue, oldValue, options) => {
            if (newValue !== undefined) {
                visible.value = true
            }
        })

        const toogleChat = () => {

            if(newMessageDisplay) {
              store.dispatch("chat/newMessageDisplay", false)
            }

            if (firstOpen) {
                firstOpen = false

                if (schedule.value.available && !isConnected.value) {
                    store.dispatch('chat/register', {
                        email: '',
                        phone: ''
                    })
                }
            }

            open.value = !open.value
            window.chatOpen = open.value
          
            if (window.chatOpen && window.lastMessageID !== '') {
                const channel = channelsSDK.getChannel(channelID.value)
                if (channel) {
                    channel.publish('read_message', window.lastMessageID, () => {})

                    window.lastMessageID = ''
                }
            }
        }

        return {
            open,
            visible,
            toogleChat,
            isConnected,
            schedule,
            newMessageDisplay,
            Chat
        }
    }
})
</script>

<style scoped lang="scss">
.ant-badge-count {
    z-index: auto;
    min-width: 20px;
    height: 20px;
    padding: 0 6px;
    color: #fff;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    text-align: center;
    background: #ff4d4f;
    border-radius: 10px;
    box-shadow: 0 0 0 1px #fff;
}

.ant-badge-not-a-wrapper .ant-scroll-number-custom-component, .ant-badge-not-a-wrapper .ant-scroll-number {
    position: relative;
    display: block;
}

.ant-layout, .ant-layout * {
    box-sizing: border-box;
}
.ant-scroll-number {
    overflow: hidden;
}

.ant-badge {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: absolute;
    display: inline-block;
    line-height: 1;
}

@media only screen and (max-width: 600px) {
    #chat_layout {
        margin-right: 0 !important;
        margin-bottom: 0 !important;
        // height: 100vh !important;
        z-index: 100000;
        // bottom: -74px;
    }

    .chat-floating-container {
        height: 100vh !important;
        min-height: 100vh !important;
        width: 100vw !important;
        padding-bottom: 0 !important;
        border-radius: 0 !important;
        margin-bottom: -72px !important;
        z-index: 1;
    }

    .chat-toggle {
        margin-bottom: 8px;
        margin-right: 8px;
    }
}

#chat_layout {
    z-index: 1000;
    max-height: 100vh;
}

.chat-floating-container {
    border-radius: 6px;
    padding-bottom: 0.8rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 5px 40px;
}

.scrollbar-w-2::-webkit-scrollbar {
    width: 0.25rem;
    height: 0.25rem;
}

.scrollbar-track-blue-lighter::-webkit-scrollbar-track {
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity));
}

.scrollbar-thumb-blue::-webkit-scrollbar-thumb {
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity));
}

.scrollbar-thumb-rounded::-webkit-scrollbar-thumb {
    border-radius: 0.25rem;
}
</style>

