<template>
  <div class="chat-input-container">
    <div class="emoji-pickerx-container" style="max-height: 0" ref="mojiPicker">
      <Picker
        color="#2c3e50"
        :data="emojiIndex"
        set="apple"
        title="Escolha um emoji"
        emoji="point_up_2"
        native="true"
        @select="onEmojiSelected"
      />
    </div>
    <div class="px-4 pt-4 mb-2 sm:mb-0 input-container">
      <div class="relative flex">
        <audio
          :src="require('@/assets/sound/message_submit.wav')"
          ref="sendSoundPlayer"
          id="sendMessage"
          class="hidden"
        />
        <span class="absolute inset-y-0 flex items-center"> </span>
        <div
          class="w-full flex focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pl-4 bg-gray-200 rounded-full py-3"
        >
          <textarea
            id="message-input"
            type="text"
            v-model="input"
            @keyup.enter="enterPress"
            @keyup="onMessageChange"
            placeholder="Send message..."
            class="w-full focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 message-input"
          />
        </div>
        <div class="absolute right-0 items-center inset-y-0 sm:flex">
          <button
            v-on:click="openEmojis"
            type="button"
            class="inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              class="h-6 w-6 text-gray-600"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
          </button>
          <button
            v-on:click="sendMessage"
            type="button"
            class="inline-flex items-center justify-center rounded-full h-12 w-12 transition duration-500 ease-in-out text-white bg-gray-900 hover:bg-gray-700 focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="h-6 w-6 transform rotate-90"
            >
              <path
                d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, computed, ref } from 'vue'
import { useStore } from '@/store'
import { useChannels } from '@/plugins/chat/index'
import xid from 'xid'
import data from 'emoji-mart-vue-fast/data/all.json'
import { Picker, EmojiIndex } from 'emoji-mart-vue-fast/src'
import * as GraphemeSplitter from 'grapheme-splitter'

export default defineComponent({
  components: { Picker },
  setup() {
    const splitter = new GraphemeSplitter()
    const emojiIndex = new EmojiIndex(data)
    const store = useStore()
    const channelID = computed(() => store.getters['chat/currentChannel'])
    const input = ref('')
    const mojiPicker = ref(null)
    const sendSoundPlayer = ref(null)
    const isEmojiOpen = ref(false)

    const onEmojiClose = () => {
      toggleEmojis()
    };

    const onEmojiSelected = (moji) => {
      input.value += moji.native
    };

    const openEmojis = () => {
      toggleEmojis()
    };

    const isEmoji = (word) => {
      return word.startsWith(':') && word.endsWith(':')
    };


    const toggleEmojis = () => {
      isEmojiOpen.value = !isEmojiOpen.value

      if (isEmojiOpen.value) {
        mojiPicker.value.style.maxHeight = 'calc(100% - 0px)'
      } else {
        mojiPicker.value.style.maxHeight = 0
      }
    }

    const onMessageChange = (value) => {
      // When we press enter to send the text aread add the \n
      // If we have the \n and nothing else then ignore it
      if (value.keyCode === 13) return;
      if (value.target.value === '\n') return

      let changedInput = value.target.value

      const parts = changedInput.split(' ')

      let lastWord = parts[parts.length - 2]

      if (lastWord && isEmoji(lastWord)) {
        lastWord = lastWord.slice(1, lastWord.length - 1)

        let result = emojiIndex._emojis[lastWord]

        if (result) {
          if (!result.native) {
            result = Object.values(result)[0]
          }

          changedInput = changedInput.replace(
            ':' + lastWord + ':',
            result.native
          )
        } else {
          const results = emojiIndex.search(lastWord)

          let result = ''

          if (results) {
            for (let i = 0; i < results.length; i++) {
              const emojiData = results[i]
              for (let y = 0; y < emojiData.short_names.length; y++) {
                if (emojiData.short_names[y] === lastWord) {
                  result = emojiData
                  break;
                }
              }

              if (result !== '' && result) break
            }

            if (result) {
              changedInput = changedInput.replace(
                ':' + lastWord + ':',
                result.native
              )
            }
          }
        }
      } else if (parts.length > 1) {
        let emojiData = ''

        const emojiID = emojiIndex._emoticons[lastWord]

        if (emojiID) emojiData = emojiIndex._emojis[emojiID]

        if (emojiData !== '') {
          changedInput = changedInput.replace(lastWord, emojiData.native)
        }
      }
      input.value = changedInput
    };

    const enterPress = (event) => {
      if(!event.shiftKey) {
        sendMessage();
      }
    };

    const sendMessage = () => {
      if (input.value === '' || input.value === '\n') {
        input.value = "";
        return;
      }

      if (isEmojiOpen.value) toggleEmojis()

      const channelsSDK = useChannels()
      const channel = channelsSDK.getChannel(channelID.value)

      const messageParts = splitter.splitGraphemes(input.value)

      for (let i = 0; i < messageParts.length; i++) {
        const emojiData = emojiIndex._nativeEmojis[messageParts[i]]

        if (emojiData) {
          messageParts[i] = ':' + emojiData.id + ':'
        }
      }

      const processedMessage = messageParts.join('')

      channel.publish(
        'text_message',
        JSON.stringify({
          id: xid.generateId(),
          message: processedMessage
        }),
        function () {}
      )

      input.value = ''
      // For some reason if there emojis the value won't clear itself
      // document.getElementById("message-input").value = "";
      const player = sendSoundPlayer.value
      player.load()
      player.play()
    };

    const msg = store.getters['chat/initialMessage']
    if (msg) {
      input.value = msg
    }

    return {
      input,
      sendMessage,
      emojiIndex,
      onMessageChange,
      enterPress,
      sendSoundPlayer,
      onEmojiClose,
      onEmojiSelected,
      isEmojiOpen,
      openEmojis,
      mojiPicker,
    }
  },
})
</script>

<style scoped lang="scss">
@media only screen and (max-width: 600px) {
  .emoji-pickerx-container {
    width: 100vw !important;
    bottom: 80px !important;
  }
}

.emoji-pickerx-container {
  overflow: hidden;
  transition: all 0.2s;
  position: fixed;
  bottom: 170px;
  z-index: 1;
}

.chat-input-container {
  display: flex;
  flex-flow: column;
}

.message-input {
  height: 30px;
  background-color: transparent;
  width: 70%;
  resize: none;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(40px);
  opacity: 0;
}
</style>
