export const SET_ON_TEXT_MESSAGE = 'SET_ON_TEXT_MESSAGE'
export const SET_ON_SYNC_MESSAGE = 'SET_ON_SYNC_MESSAGE'
export const SET_CURRENT_CHANNEL = 'SET_CURRENT_CHANNEL'
export const SET_CURRENT_CLIENT = 'SET_CURRENT_CLIENT'
export const SET_CURRENT_CLIENT_WITH_INITIAL_MESSAGE = 'SET_CURRENT_CLIENT_WITH_INITIAL_MESSAGE'
export const SET_ON_TEXT_MESSAGE_UPDATE = 'SET_ON_TEXT_MESSAGE_UPDATE'
export const SET_ON_TEXT_MESSAGE_BEFORE = 'SET_ON_TEXT_MESSAGE_BEFORE'
export const SET_IS_CONNECTED = 'SET_IS_CONNECTED'
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN'
export const SET_SCHEDULE = 'SET_SCHEDULE'
export const AUTH_LOGIN_OK = 'AUTH_LOGIN_OK'
export const AUTH_LOGIN_FAILED = 'AUTH_LOGIN_FAILED'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_REQUESTING = 'AUTH_REQUESTING'
export const SET_NEW_MESSAGE_DISPLAY = 'SET_NEW_MESSAGE_DISPLAY'
export const SET_SHOP = 'SET_SHOP'
export const SETUP_SHOP_SET_TYPE = 'SETUP_SHOP_SET_TYPE'
export const SETUP_SHOP_SET_PLUGIN_ACTIVE = 'SETUP_SHOP_SET_PLUGIN_ACTIVE'
export const SETUP_SHOP_SET_PERM = 'SETUP_SHOP_SET_PERM'
export const SETUP_SHOP_SYNC_STATUS = 'SETUP_SHOP_SYNC_STATUS'
