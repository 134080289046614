import { ChannelsSDK } from 'channels_sdk'
import { useStore } from '@/store'
import { formatEmojis } from "../../components/Chat/util"

export const beforeFetchNumber = 15
let channels = {}

export function initChat(options, key = "visitant") {
  let channelsSDK = new ChannelsSDK(options)
  channelsSDK.setLogEnabled(true)
  channels[key] = channelsSDK
}

export function useChannels(key = "visitant") {
  return channels[key]
}

export function handleTextMessage(object, mode = 'after') {
  const data = object.data
  const store = useStore()
  const lastMessage = object.message
  const currentClient = store.getters['chat/currentClient']

  const textMessage = {
    message: JSON.parse(data.payload),
    timestamp: data.timestamp,
    me: data.senderID == currentClient,
    block: []
  }
  textMessage.message.message = formatEmojis(textMessage.message.message)

  const isSameClient =
    lastMessage != null ? lastMessage.me == textMessage.me : false
  if (lastMessage == null || !isSameClient) {
    return { mode: 'push', message: textMessage }
  } else {
    if (mode == 'after') lastMessage.block.push(textMessage)
    else if (mode == 'before') {
      const tmp = {
        message: lastMessage.message,
        timestamp: lastMessage.timestamp,
        me: lastMessage.me,
        block: []
      }
      lastMessage.message = textMessage.message
      lastMessage.timestamp = textMessage.timestamp
      lastMessage.me = textMessage.me
      lastMessage.block.splice(0, 0, tmp)
    }
    return { mode: 'update', message: lastMessage }
  }
}

/*
*   Get private channels (notification, default, sync)
*   and subscribe to them
*/
export function connectClient() {
  const store = useStore()
  let channel = useChannels("client");
  channel.fetchPrivateChannels().then(channels => {
    channel.connect("", isConnected => {
      store.dispatch('chat/onConnect', { key: "client", value: isConnected} )
      if(!isConnected)
        return;
      channels.forEach(channelSub => {
        channelSub = channel.getChannel(channelSub.id);
        channelSub.setOnOnlineStatusUpdate(x => {})
        channelSub.setOnJoin(clientID => {})
        channelSub.setOnLeave(clientID => {})
        channelSub.setOnMessage(event => {
          store.dispatch('storeSetup/newSyncMessage', event)
          //{senderID: "", eventType: "sync_status", payload: "{\"clientID\":\"renatito\",\"target\":\"ProcessWordpressTax\"}", channelID: "renatito-sync", timestamp: 1623410519}
        })
        channelSub.subscribe(() => {
          if(channelSub.getID().includes("sync"))
            channelSub.fetchLastEvents(50).then(elements => {
              elements.forEach(element => {
                store.dispatch('storeSetup/newSyncMessage', element)
              })
            });
        })
      });
    })
  });
}


export function connect(channel, token) {
  const store = useStore()
  let channelsSDK = useChannels();
  channelsSDK.setToken(token)
  channelsSDK.registerChannel(channel)
  localStorage.setItem('token', token)

  channelsSDK.connect('', isConnected => {
    store.dispatch('chat/onConnect', { key: "visitant", value: isConnected} )

    if(!isConnected)
      return;
    const channelSub = channelsSDK.getChannel(channel.id)
    channelSub.setOnOnlineStatusUpdate(x => {})
    channelSub.setOnJoin(clientID => {})
    channelSub.setOnLeave(clientID => {})
    channelSub.setOnMessage(event => {
      if (event.eventType === 'text_message') {
        if (window.chatOpen) {
          channelSub.publish(
            'read_message',
            JSON.parse(event.payload).id,
            () => {}
          )
          store.dispatch('chat/newMessageDisplay', false)
          window.lastMessageID = ''
        } else {
          window.lastMessageID = JSON.parse(event.payload).id
          store.dispatch('chat/newMessageDisplay', true)
        }
        const msg = event
        store.dispatch('chat/onEvent', msg)
      }
    })
    channelSub.subscribe(() => {
      store.dispatch('chat/currentChannel', channel)
    })
  })
}

export default channels
