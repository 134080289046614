
<template>
  <div class="py-3 chat-header-container">
    <div
      style="display: none"
      v-on:click="$emit('closed')"
      class="close-container"
    >
      <plus />
    </div>
    <span class="text-white chat-header-name">NAPPS Helper</span>
    <span class="text-green-500 chat-header-status-indicator">
      <svg width="10" height="10">
        <circle cx="5" cy="5" r="5" fill="currentColor"></circle>
      </svg>
    </span>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import Plus from "@/components/Icons/Plus.vue";

export default defineComponent({
  emits: ["closed"],
  components: { Plus },
  setup() {},
});
</script>

<style scoped lang="scss">
@media only screen and (max-width: 600px) {
  .chat-header-container {
    border-radius: 0 0 !important;
  }

  .close-container {
    cursor: pointer;
    grid-column: 3;
    grid-row: 2;
    transform: rotate(45deg);
    transition: all 0.2s;
    border-radius: 5rem;
    width: 25px;
    height: 25px;
    overflow: hidden;
    grid-row: 1;
    grid-column: 1;
    display: inline-block !important;
    margin-left: 9px;

    &:hover {
      background-color: rgba(209, 213, 219, 255);
    }

    svg {
      display: inline-block;
      fill: white;
    }
  }
}

.chat-header-container {
  display: grid;
  grid-template-columns: 1fr auto auto 1fr;
  grid-template-rows: 1fr;
  width: 100%;
  text-align: center;
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  border-radius: 6px 6px 0 0;
  box-shadow: rgb(0 0 0 / 10%) 0px 2px 30px 0px;
}

.chat-header-name {
  grid-column: 2;
  grid-row: 1;
}

.chat-header-status-indicator {
  grid-column: 3;
  grid-row: 1;
  margin-top: 7px;
  margin-left: 8px;
}
</style>

