import { createI18n } from 'vue-i18n'

// 1. Ready translated locale messages
// The structure of the locale message is the hierarchical object structure with each locale as the top property
const messages = {
	en: {
		home: {
			message_button1: 'Start Free Trial',
			message_home_title1: 'Shape your future create a mobile app now',
			message_home_top_title: 'A Mobile App for your ecommerce',
			message_home_middle1: 'Websites are no longer enough, improve your customer experience with a fully customizable mobile app. Increase conversion with excellent shopping experience, fast checkout and push notifications.',
			watch_video: 'Watch Video',
			credit_card: "No credit card needed",
			request_demo_title: 'Seems to good to be true? Let us prove you ARE wrong!',
			request_demo_button: 'Request Demo',
			ecommerce_plataform_title: 'do you have an online store? Turn it into a mobile app in minutes',
			ecommerce_plataform_description: 'Transform your Shopify or WooCommerce store into a mobile app in minutes.',
			ecommerce_plataform_shopify: 'We integrate with Shopify, giving your users the best mobile shopping app!',
			ecommerce_plataform_woocommerce: 'Got woocommerce? Increase your user experience  with our service.',
			message_advantages: 'App Advantages',
			message_home_brand1: 'Brand Notoriety',
			message_home_brand2: 'Mobile apps are an effective solution for brand notoriety when compared with other marketing strategies. With NAPPS your brand will gain visibility and awareness to the targeted audience.',
			message_home_experience1: 'User Experience',
			message_home_experience2: 'Give your customers a seamless shopping experience sticking them with your store. Your brand will be unforgettable.',
			message_home_convert1: 'Convert Even More',
			message_home_convert2: "The one page checkout system simplifies the payment process and increases your brand's overall conversion rate.",
			message_home_push1: 'New season? New collection? Let your users know about your exciting news!',
			message_home_push2: 'With push notifications you can engage with your clients about new promotions, new collections and new products.',
			message_dashboard_title1: 'Explore our dashboard, the new tool for your app.',
			message_dashboard_title2: 'Built by us, made for you!',
			message_explore_dashboard: 'Check out our Dashboard',
			dashboard_design_title: 'APP Design',
			dashboard_design_description: 'Remember when we said a “fully customizable app”? You can do it, with our drag’ n drop components creating multiple homes to fit your needs.',
			dashboard_notification_title: 'Notifications',
			dashboard_notification_description: 'Manual and automatic notifications with all the options to fit your brand communication. Just define your strategy and make it happen.',
			dashboard_analytics_title: 'Analytics',
			dashboard_analytics_description: 'Without third parties involved you get all the data you need to develop your sales strategies. From users’ actions, throught funnels to product behaviour, you can analyze all the data and costumer behaviour.',
			dashboard_settings_title: 'Settings',
			dashboard_settings_description: 'You are one switch away from taking advantage of a wide range of features, like product reviews, discount codes and many more.',
			message_home_dashboard2: 'Create your home page structure with  the assistance of our drag-and drop system. The simple block editor will allow you to change your homepage designs in seconds.',
			message_home_dashboard3: 'Branding',
			message_home_dashboard4: 'Maintain your brand identity by customising your mobile app layouts.'
		},
		product: {
			title: 'Every feature shaped to boost your sales',
			description: 'Explore our features and know what you can get in your app. Everything was created to allow you scale your business to the next level.',
			section_title: 'Every feature built to provide a better user experience',
			account_title: 'Customer Account',
			account_message: 'Keep your users data secure while using their information to send segmented push notifications',
			filter_title: 'Sort & Filter',
			filter_message: 'Detailed product searches and specific filters allow users to find products faster',
			product_title: 'Product Detail',
			product_message: 'Customize your product page the way you feel like',
			wishlist_title: 'Wishlist',
			wishlist_message: 'Allow your users to track their favourite products and keep them up to date with new promotions',
			cart_title: 'Cart',
			cart_message: 'Add products to your cart and sync them across your devices',
			checkout_title: 'Fast Checkout',
			checkout_message: 'Offer your customers a three step checkout making it as simple as it gets',
			notification_section_title: 'Call to action with push notifications',
			push_title: 'Push Notification',
			push_message: 'Engage with your customers in a simple and efficient way through push notifications',
			abandoned_title: 'Abandoned Cart',
			abandoned_message: 'Remember your users about their forgotten carts',
			stock_title: 'Back in stock',
			stock_message: 'Notify your users that their subscribed product is back in stock',
			segmented_title: 'Push Segmented',
			segmented_message: 'Target a group of users with accurately segmented notifications',
			section_title3: 'Control your app with our dashboard',
			create_push_title: 'Create a Push',
			create_push_message: 'The way you can send notifications is very easy and user-friendly. Just text your title, message, select the content and send it.',
			drag_title: 'Drag-and-Drop',
			drag_message: 'Build and design your personalized  homepage with our quick and easy intuitive system.',
			branding_title: 'Branding',
			branding_message: 'Give the app your brand identity with your own colors and logo. Costumizable by you.',
		},
		pricing: {
			title: 'Prices for every business',
			description: 'Ours plans are tailored for every brand, whether you are just starting or already established. The prices are what you see below, no hidden fees, no surprises.',
			message_detailed: 'SEE DETAILED PLAN',
			message_fees: 'Additional fees by store:',
			message_year: 'year',
			message_fee: 'one time fee',
			iva: '*Is subject to the VAT rate',
		},
		footer: {
			title: 'Creating your app is even faster than getting here',
			subtitle: 'Start free 14-days trial',
			button: 'Book a demo',
			company: "Company",
			product: "Product",
			policies: "Policies",
			donwload_now: "Donwload now",
			follow_us: "Follow us",
			terms_use: "Terms Of Use",
			privacy_policy: "Privacy Policy",
			data_processing: "Data Processing",
			cookies_policy: "Cookies Policy",
			choose_language: "Choose a language:",
			english: "English",
			portuguese: "Portuguese",
		},
		login: {
			title: 'Login to your dashboard',
			startnow: 'Dont\' have an account',
			email: 'Email Address',
			placeholder_email: 'Enter Email Address',
			password: 'Password',
			placeholder_password: 'Enter Password',
			forgot: 'Forgot Password?',
			error_email_required: 'Fill your email address',
			error_password_required: 'Fill your password',
			error_email_invalid: 'Invalid email address',
			incorrect_information: 'Inocorrect credentials. Try again.',
		},
		startnow: {
			title: 'Start selling now',
			firstName: 'First Name',
			lastName: 'Last Name',
			email: 'Email Address',
			phone: 'Phone Number',
			password: 'Password',
			website: 'Website URL',
			already: 'Already have an account? ',
			create: 'Create Account',
			placeholder_firstName: 'Enter First Name',
			placeholder_lastName: 'Enter Last Name',
			placeholder_email: 'Enter Email Address',
			placeholder_phone: 'Enter Phone Number',
			placeholder_password: 'Enter Password',
			placeholder_website: 'Enter Website',
			error_required_firstname: 'Fill your first name',
			error_required_lastname: 'Fill your last name',
			error_required_email: 'Fill your email address',
			error_invalid_email: 'Invalid email address',
			error_required_phone: 'Fill your phone number',
			error_required_password: 'Fill your password',
			error_required_website: 'Fill your website url',
			accept_terms: 'I read and accept the ',
			terms_conditions: 'Terms and Conditions ',
			accept_terms_2: 'and ',
			policy: 'Privacy and Policy',
			error_server: 'Something went wrong, please try again.',
			https_error: 'Your website require https to work properly with our system.',
			already_account_error: 'We already have one account with this information.',
			invalid_url: 'Your website has an invalid url.',
			domain_error: 'We already have one account with the same domain name',
		},
		forget: {
			message_forgot_title: 'Forgot password?',
			message_forgot_description: 'Enter your email below and we’ll send you a reset password link.',
			message_forgot_submit: 'Submit'
		},
		about: {
			mission_title: 'MISSION',
			mission_description: 'Make mobile your best sales channel.',
			story_title: 'OUR STORY',
			story_description: 'NAPPS started when two friends needed a mobile application, however, the market lacked solutions that offered reduced costs and good client support. We wanted a mobile app that would allow a better user conversion in the long run, thus, we created a platform that gives the power to start and grow business to millions of users. NAPPS is founded on February of 2021 and based in Portugal with office in Porto, additionally, NAPPS is 100% owned by its founders.',
			service_title: 'OUR SERVICE',
			service_description: 'NAPPS is a platform that allows the creation of native mobile apps for e-commerce in record time and without programming knowledge. At the moment we offer integration with WooCommerce and Shopify. With our plataform we offer app design and branding customization, real time updates of your shop inventory, tools like push notifications, wishlists and user accounts.We will keep upgrading our services with new functionalities.',
			team_title: 'OUR TEAM',
			role_tile_01: 'FOUNDER & CEO',
			role_tile_02: 'FOUNDER & CTO',
			role_tile_03: 'COO',
			role_tile_04: 'DEVELOPER',
			role_tile_05: 'DEVELOPER',
			role_tile_06: 'DESIGNER',
			quote: 'We are a team of ambitious and motivated people with the sole objective of creating a worldwide impact.',
		},
		error: 'Something went wrong',
		auth: {
			reset: {
				title: 'Reset password',
				desc: 'Insert your new password',
				passwords_mismatch: 'Password\'s does not match',
				success: 'Your password was updated'
			},
			success: 'An email with instructions to reset your password was sent.',
			forgot_email_does_not_exists: 'Your email does not have an account.'
		},
		navbar: {
			features: 'Product',
			pricing: 'Pricing',
			about: 'About',
			contactus: 'Contact Us',
			support: 'Support',
			login: 'Login',
			startnow: 'Start Now',
			dashboard: 'Dashboard',
			configure: 'Configure',
			logout: 'Logout',
			press: 'Press',
			resources: 'Resources',
		},
		support: {
			title: 'Support',
		},
		contact: {
			title: 'Let’s start a conversation! You won’t regret it!',
			sub_title: 'Don’t keep any questions to yourself. Chat with us!',
			full_name_label: "Full Name",
			full_name_placeholder: "Enter Full Name",
			country_label: "Country",
			country_placeholder: "Enter Country",
			email_label: "Email",
			email_placeholder: "Enter Email",
			phone_label: "Phone Number",
			phone_placeholder: "Enter Phone Number",
			company_label: "Company",
			company_placeholder: "Enter Company",
			storeUrl_label: "Store URL",
			storeUrl_placeholder: "Enter Store URL",
			error_required_fullName: 'Fill your name',
			error_required_country: 'Fill your country',
			error_required_email: 'Fill your email',
			error_invalid_email: 'Invalid Email',
			error_required_phone: 'Fill your phone number',
			error_required_company: 'Fill your company',
			error_required_storeURL: 'Fill your store url',
			button: 'Book Now',
			title_contact_done: "Thank you the contact",
			message_contact_done: "We will contact you in the next 24 hours",
		},
		synchronization : {
			next: 'Next',
			back: 'Previous',
			choose_store: 'Choose Plataform',
			configure: 'Configure',
			sync: 'Sync Store',
			open_dashboard_title: 'Do you want to advance to the dashboard?',
			open_dashboard_desc: 'If your store has a lot of products and settings this process may take a while. You can skip to your dashboard and start exploring your new tool. The information will keep being synced. Be aware that all of your products will not be available yet!',
			open_dashboard_yes: 'Start now',
			open_dashboard_no: "I'll wait here",
		},
		chooseStore: {
			title: 'Choose Your Plataform',
			description: 'Choose your eCommerce store to start the setup process. You need to choose the right one in order to get all the information to start your app.',
			button: 'Connect',
			shopify_error: 'We will be on Shopify soon, you will be notified by the time!',
		},
		connect: {
			title: 'Connect',
			description: 'Install our plug-in in your wordpress backoffice and give acess to NAPPS. After this, just need to wait some minutes to start your app personalization',
			plugin_title: 'Donwload & Install our plug-in',
			plugin_description: "If you didn't install our plug-in already you can do it from woocommecer store or download it and then upload into your backoffice",
			plugin_button: 'Download',
			access_title: 'Give access to NAPPS to sync your store',
			access_description: 'We need acess to your store to get all the information to create your app. This will trigger your products, collection, prices and settings to get all the information on both sides updated.',
			access_button: 'Give access',
			error_plugin: 'We could not make a connection to your website or the plugin is not properly installed',
			error_store_perms: 'Its seems that you refuse the permissions to access your shop.',
			error_credentials: 'Its seems that we recieved invalid credencials, please try again.',
		},
		synchronize: {
			title: 'Synchronization',
			description: 'The sync process is on the way, it may last some minutes depending on your store size. You can proceed to your fresh dashboard and we will notify you when it\'s done.',
		},
		press: {
			title: 'MEDIA CONTACT',
			subtitle: 'MEDIA',
			description: 'Below you can find the media contact. We hope you understand that the contacts are for journalists only. If you have questions about the platform or any other non-media-related questions, please contact us.',
			button: 'CONTACT US',
			link: 'napps.pt/contactus',
			our_company: 'OUR COMPANY',
			media_kit: 'Media Kit and Brand Guidelines',
			media_kit_date: 'December 10th, 2021',
			leadership_team: 'Meet the NAPPS Leadership Team',
			leadership_team_date: 'December 10th, 2021',
			founders_letter_date: 'December 10th, 2021',
			sale_methods: 'SALE METHODS HAVE EVOLVED AND OUR COMPANY GIVES YOU THE TOOLS TO FOLLOW THAT EVOLUTION',
			help_brands: 'We create technologies that help brands connect with customers, improve users\' shopping experience and grow businesses.',
			see_more: 'See More About Our Company',
			contact_us: 'Contact us through:'
		},
		team: {
			title: 'MEET NAPPS\' LEADERSHIP TEAM',
			pedro_pontes: 'FOUNDER & CEO',
			nuno_batista: 'FOUNDER & CTO',
			renato_mota: 'COO',
			read_bio: 'Read Full Bio'
		},
		notfound: {
			title: 'How about starting again?',
			subtitle: 'Seems like you are going nowhere...	',
		},
	},
	pt: {
		home: {
			message_button1: 'Começa com 14 dias grátis',
			message_home_title1: 'O ecommerce é o presente a napps é o teu futuro',
			message_home_top_title: 'Aplicação para o teu ecommerce',
			message_home_middle1: 'Os sites não foram concebidos para telemóveis e o responsivo é apenas um remendo. Cria uma aplicação nativa e aumenta a conversão com uma excelente experiência de compra, checkout rápido e push notifications.',
			watch_video: 'assiste ao vídeo',
			credit_card: "Não é necessário cartão de crédito",
			request_demo_title: 'Parece bom de mais para ser verdade? Deixa-nos provar que estás errado!',
			request_demo_button: 'Solicita uma Demo',
			ecommerce_plataform_title: 'Ter uma aplicação deixou de ser algo complexo e exclusivo',
			ecommerce_plataform_description: 'Agora é bastante fácil transformares a tua loja Shopify ou Woocommerce numa aplicação',
			ecommerce_plataform_shopify: 'Integramos com Shopify, dando aos teus clientes a melhor aplicação de ecommerce! ',
			ecommerce_plataform_woocommerce: 'Tens Woocommerce? Eleva a outro nível a experiência de compra dos teus clientes!',
			message_advantages: 'Vantagens Aplicação',
			message_home_brand1: 'Notoriedade da Marca',
			message_home_brand2: 'As aplicações são uma solução eficiente para a notoriedade da marca, quando comparadas com outras estratégias de marketing. Com a NAPPS a tua marca ganha a visibilidade e notoriedade do público-alvo.',
			message_home_experience1: 'Experiência de compra',
			message_home_experience2: 'Uma experiência de compra muito intuitiva faz os utilizadores concentrarem-se unicamente na compra, sem preocupações na utilização. Uma verdadeira ligação entre a marca e os clientes.',
			message_home_convert1: 'Maior Conversão',
			message_home_convert2: "Uma aplicação totalmente concebida para gerar compras impulsivas. Todo o sistema de checkout foi simplificado para gerar vendas e aumentar a taxa de conversão.",
			message_home_push1: 'Novo produto? Nova coleção? Informa os teus clientes de todas as novidades que tens para oferecer!',
			message_home_push2: 'Com o envio de notificações, podes informar o utilizador de promoções, novas coleções ou até novos produtos. Através de notificações automáticas, podes relembrar-los dos carrinhos abandonados ou checkouts.',
			message_dashboard_title1: 'Descobre as ferramentas disponíveis na nossa dashboard',
			message_dashboard_title2: 'Desenvolvida por nós a pensar em ti!',
			message_explore_dashboard: 'Apresentamos-te as nossas funcionalidades',
			dashboard_design_title: 'Design da APP',
			dashboard_design_description: 'Podes desenvolver, através do sistema drag-and-drop, várias páginas iniciais para a tua aplicação. É 100% personalizável e consegues adaptar à tua marca e aos teus produtos.',
			dashboard_notification_title: 'Notificações',
			dashboard_notification_description: 'Automatizadas ou manuais, damos-te todas as opções para conseguires personalizar e adaptar a comunicação à tua marca. Apenas tens que definir a estratégia e implementa-la.',
			dashboard_analytics_title: 'Analytics',
			dashboard_analytics_description: 'A gestão da tua aplicação fica mais fácil com os dados que te proporcionamos. Consegues analisar os teus utilizadores dentro da aplicação ou até mesmo a performance dos teus produtos.',
			dashboard_settings_title: 'Definições',
			dashboard_settings_description: 'Estás a um click de distância de conseguires tirar partido de um grande leque de funcionalidades que a nossa plataforma tem disponível. Podes ativar os comentários no produto, códigos de desconto e muitas outras. ',
			message_home_dashboard2: 'Create your home page structure with  the assistance of our drag-and drop system. The simple block editor will allow you to change your homepage designs in seconds.',
			message_home_dashboard3: 'Branding',
			message_home_dashboard4: 'Maintain your brand identity by customising your mobile app layouts.'
		},
		product: {
			title: 'Incrementa vendas com as funcionalidades ideais',
			description: 'Explora as nossas funcionalidades e descobre o que podes encontrar na tua aplicação. Tudo foi criado para te permitir escalar o teu negócio para outro nível.',
			section_title: 'Cada funcionalidade foi pensada para oferecer uma melhor experiência ao utilizador',
			account_title: 'Conta de Cliente',
			account_message: 'Guarda a informação dos utilizadores em segurança e utiliza-a para enviar push notification segmentadas',
			filter_title: 'Pesquisa e Filtro',
			filter_message: 'Permite que os teus utilizadores encontrem os seus produtos favoritos com as nossas refinadas opções de pesquisa.',
			product_title: 'Detalhe do Produto',
			product_message: 'Personaliza a tua página de produto de acordo com as suas características.',
			wishlist_title: 'Wishlist',
			wishlist_message: 'Permite aos utilizadores guardarem os seus produtos favoritos para se manterem atualizados com as novas promoções.',
			cart_title: 'Carrinho',
			cart_message: 'Adiciona produtos ao teu carrinho e sincroniza-os através dos seus dispositivos',
			checkout_title: 'Checkout rápido',
			checkout_message: 'Numa só página e em apenas 3 cliques. Converte mais e em menos tempo.',
			notification_section_title: 'Torna o telemóvel o teu melhor canal de vendas',
			push_title: 'Push Notification',
			push_message: 'Interage com os teus utilizadores de uma forma simples e eficiente através das notificações.',
			abandoned_title: 'Carrinho abandonado',
			abandoned_message: 'Relembra os teus utilizadores que deixaram artigos no carrinho. Vais conseguir recuperar vendas perdidas',
			stock_title: 'Produto disponível',
			stock_message: 'Avisa os utilizadores que subscreveram um produto que já está novamente disponivel com stock',
			segmented_title: 'Notificação segmentada',
			segmented_message: 'Permitimos o envio de notificações segmentadas por grupos',
			section_title3: 'Todas as Funcionalidades para aumentar as vendas',
			create_push_title: 'Crie uma Push-Notification',
			create_push_message: 'Cria ou agenda uma notificação. Não esquecendo que também podes editar o texto das automatizadas.',
			drag_title: 'Drag-and-Drop',
			drag_message: 'Constrói a página inicial e pesquisar de forma fácil, rápida e personalizada.',
			branding_title: 'Branding',
			branding_message: 'Personaliza a tua aplicação com o teu branding, utiliza as tuas cores, logos, tipografia e icones.',
		},
		pricing: {
			title: 'Preços para todas as Marcas',
			description: 'Os nossos planos são adaptados a cada marca, quer esteja apenas a começar ou já estabelecida. Os preços são os que vê abaixo, sem taxas escondidas, sem surpresas.',
			message_detailed: 'VER PLANO DETALHADO',
			iva: '*Sujeito a IVA à taxa em vigor',
			message_fees: 'Para conseguires publicar a tua app precisas de criar contar em:',
			message_year: 'ano',
			message_fee: 'pagamento único'
		},
		footer: {
			title: 'Criar a tua aplicação é bem mais rápido do que chegar até aqui',
			subtitle: 'Começa já com 14 dias grátis',
			button: 'Agenda uma demo',
			company: "Empresa",
			product: "Produto",
			policies: "Políticas",
			download_now: "Donwload agora",
			follow_us: "Siga-nos",
			terms_use: "Termos de Uso",
			privacy_policy: "Políticas de Privacidade",
			data_processing: "Processameno de Dados",
			cookies_policy: "Políticas de Cookies",
			choose_language: "Escolha a língua:",
			english: "Inglês",
			portuguese: "Português",
		},
		login: {
			title: 'Entra na tua Dashboard',
			startnow: 'Não tens uma conta?',
			email: 'Endereço de e-mail',
			placeholder_email: 'Introduza Endereço de e-mail',
			password: 'Password',
			placeholder_password: 'Introduza Password',
			forgot: 'Esqueceste-te da tua palavra-passe?',
			error_email_required: 'Preencha o email',
			error_password_required: 'Preencha a password',
			error_email_invalid: 'Endereço email inválido',
			incorrect_information: 'Credencias incorretas. Tente novamente!',
		},
		startnow: {
			title: 'Começa já a vender',
			firstName: 'Primeiro Nome',
			lastName: 'Último Nome',
			email: 'Endereço de e-mail',
			phone: 'Número de telemóvel',
			password: 'Password',
			website: 'Website URL',
			already: 'Já tens conta? ',
			create: 'Cria uma conta',
			placeholder_firstName: 'Introduza Primeiro Nome',
			placeholder_lastName: 'Introduza Último Nome',
			placeholder_email: 'Introduza Endereço de Email',
			placeholder_phone: 'Introduza Número de Telemóvel',
			placeholder_password: 'Introduza Password',
			placeholder_website: 'Introduza Website',
			error_required_firstname: 'Preencha o primeiro nome',
			error_required_lastname: 'Preencha o último nome',
			error_required_email: 'Preencha o email',
			error_invalid_email: 'Endereço de email inválido',
			error_required_phone: 'Preencha o número de telemóvel',
			error_required_password: 'Preencha a password',
			error_required_website: 'Preencha o website url',
			accept_terms: 'Eu li e aceito os ',
			terms_conditions: 'Termos e Condições ',
			accept_terms_2: 'e ',
			policy: 'Políticas de Privacidade',
			error_server: 'Ocorreu um erro, por favor tente novamente.',
			https_error: 'O website é obrigatório ter https para integrar com o nosso sistema',
			already_account_error: 'Já existe uma conta criada com esta informação.',
			invalid_url: 'O URL do website está inválido.',
			domain_error: 'Já existe uma conta com este domínio.',
		},
		forget: {
			message_forgot_title: 'Esqueceu-se da password?',
			message_forgot_description: 'Introduz o teu e-mail e irás receber um email para repor a password.',
			message_forgot_submit: 'Submeter'
		},
		about: {
			mission_title: 'MISSÃO',
			mission_description: 'Tornar o telemóvel o teu melhor canal de vendas.',
			story_title: 'A NOSSA HISTÓRIA',
			story_description: 'A NAPPS iniciou quando dois amigos entenderam que havia no mercado a necessidade de uma aplicação com custos mais reduzidos e um maior apoio ao cliente – Que possibilitaria ao utilizador maior conversão, a longo prazo. Assim, criamos uma plataforma que dá o poder a milhões de clientes em todo o mundo para iniciar e fazer crescer os seus negócios com a nossa tecnologia. Fundada em Fevereiro de 2021 e sediada em Portugal com escritórios no Porto, a NAPPS é 100% propriedade dos fundadores.',
			service_title: 'O NOSSO SERVIÇO',
			service_description: 'A NAPPS é uma plataforma que permite a criação de mobile apps para lojas ecommerce, com integração direta com as plataformas de Woocommerce e Shopify, permitindo às marcas a criação de uma mobile app nativa, em tempo recorde, sem necessidade de programação. A nossa plataforma permite a personalização de design, branding, atualizações em tempo real, sincronização do inventário diretamente com a loja web e, ainda, funcionalidades como: notificações push, whislists e contas de clientes.',
			team_title: 'A NOSSA EQUIPA',
			role_tile_01: 'FUNDADOR & CEO',
			role_tile_02: 'FUNDADOR & CTO',
			role_tile_03: 'COO',
			role_tile_04: 'DESENVOLVEDOR',
			role_tile_05: 'DESENVOLVEDOR',
			role_tile_06: 'DESIGNER',
			quote: "“Somos uma equipa de pessoas ambiciosas e motivadas, com um único objetivo: criar um impacto maciço.”",
		},
		error: 'Ocorreu um erro no servidor',
		auth: {
			reset: {
				title: 'Alterar password',
				desc: 'Introduz uma password nova',
				passwords_mismatch: 'As duas password\'s não são iguais',
				success: 'A password foi atualizada'
			},
			success: 'Foi enviado um email de recuperação.',
			forgot_email_does_not_exists: 'Não existe uma conta com esse email'
		},
		navbar: {
			features: 'Produto',
			pricing: 'Preços',
			about: 'Sobre Nós',
			contactus: 'Contacta-nos',
			support: 'Suporte',
			login: 'Entrar',
			startnow: 'Começa Já',
			dashboard: 'Dashboard',
			configure: 'Configurar',
			logout: 'Sair',
			press: "Imprensa",
			resources: 'Recursos',
		},
		support: {
			title: 'Suporte',
		},
		contact: {
			title: 'Escolhe um dia para te apresentarmos a solução.',
			sub_title: 'Não guardes perguntas para ti. Conversa connosco!',
			full_name_label: "Primeiro Nome",
			full_name_placeholder: "Introduza Primeiro Nome",
			country_label: "País",
			country_placeholder: "Introduza o País",
			email_label: "Email",
			email_placeholder: "Introduza Email",
			phone_label: "Número Telemóvel",
			phone_placeholder: "Introduza Número Telemóvel",
			company_label: "Empresa",
			company_placeholder: "Introduza Empresa",
			storeUrl_label: "URL da Loja",
			storeUrl_placeholder: "Introduza URL da Loja",
			error_required_fullName: 'Preencha Primeiro Nome',
			error_required_country: 'Preencha País',
			error_required_email: 'Preencha Email',
			error_invalid_email: 'Endereço de email inválido',
			error_required_phone: 'Preencha Número Telemóvel',
			error_required_company: 'Preencha Empresa',
			error_required_storeURL: 'Preencha URL da Loja',
			button: 'Agenda Agora',
			title_contact_done: "Obrigado pelo contacto",
			message_contact_done: "Vamos contactá-lo nas próximas 24 horas",
		},
		synchronization : {
			next: 'Seguinte',
			back: 'Anterior',
			choose_store: 'Escolher Loja',
			configure: 'Configurar',
			sync: 'Sincronizar',
			open_dashboard_title: 'Queres avançar para o dashboard?',
			open_dashboard_desc: 'Se a tua loja possuir muitos produtos este processo poderá demorar um bocado. Podes abrir o dashboard e começar a explorar a tua nova ferramenta. Toda a informação vai continuar a ser processada. Esteja ciente que nem todos os seus produtos estarão disponíveis de imediato!',
			open_dashboard_yes: 'Começar agora',
			open_dashboard_no: "Eu espero aqui",
		},
		chooseStore: {
			title: 'Escolhe a loja',
			description: 'Escolhe o teu ecommerce para começar o processo de configuração. Tens que escolher o correto para conseguirmos sincronizar toda a informação necessária para a tua app.',
			button: 'Conectar',
			shopify_error: 'A plataforma Shopify ainda não é compatível com a NAPPS.'
		},
		connect: {
			title: 'Conectar',
			description: 'Instala o nosso plug-in no backoffice do teu website e posteriormente dá acesso à Napps. Ficas apenas a uns minutos de conseguires começar a personalização da tua app.',
			plugin_title: 'Faz o download e instala o nosso plug-in',
			plugin_description: 'Se ainda não instalaste o nosso plug-in, podes faze-lo através da loja do woocommerce ou fazendo o upload no teu backoffice.',
			plugin_button: 'Download',
			access_title: 'Dá acesso à NAPPS para sincronizar a tua loja',
			access_description: 'Precisamos de acesso a tua loja para ter a informação necessária para criar a tua app. Sempre que alterares os teus produtos, coleções, preços e definições a NAPPS irá sicronizar automaticamente.',
			access_button: 'Dar acesso',
			error_plugin: 'Não foi possível estabelecer uma conexão com o seu site ou o plug-in não está instalado corretamente',
			error_store_perms: 'Parece que recusou as permissões de acesso à sua loja.',
			error_credentials: 'Parece que recebemos credenciais inválidas, tente novamente.',
		},
		synchronize: {
			title: 'Sincronização',
			description: 'O processo de sincronização já começou, pode demorar dependendo do tamanho da tua loja. Podes avançar para a tua nova dashboard e nós vamos te notificar quando estiver terminado.',
		},
		press: {
			title: 'CONTATO DE MÍDIA',
			subtitle: 'MEIOS DE COMUNICAÇÃO',
			description: 'Abaixo você pode encontrar o contato da mídia. Esperamos que perceba que os contactos se destinam apenas a jornalistas. Se tiver dúvidas sobre a plataforma ou qualquer outra pergunta não relacionada à mídia, contacte atraves de ',
			button: 'Contacte-nos',
			our_company: 'A NOSSA EMPRESA',
			media_kit: 'Kit de Comunicação e Diretrizes de Marca',
			media_kit_date: '10 de Dezembro, 2021',
			leadership_team: 'Conheça a Equipa de Liderança da NAPPS',
			leadership_team_date: '10 de Dezembro, 2021',
			founders_letter_date: '10 de Dezembro, 2021',
			sale_methods: 'OS MÉTODOS DE VENDA EVOLUIRAM E A NOSSA EMPRESA DÁ-LHE AS FERRAMENTAS PARA ACOMPANHAR A EVOLUÇÃO',
			help_brands: 'Criamos tecnologias que ajudam as marcas a conectarem com os clientes, a melhorarem a experiencia de compra dos utilizadores e a expandirem os negócios.',
			see_more: 'Ver Mais Sobre a nossa Empresa',
			contact_us: 'Contacte-nos através:'
		},
		team: {
			title: 'CONHEÇA A EQUIPA DE LIDERANÇA DA NAPPS',
			pedro_pontes: 'FUNDADOR E CEO',
			nuno_batista: 'FUNDADOR E CTO',
			renato_mota: 'COO',
			read_bio: 'Ler biografia'
		},
		},
		notfound: {
			title: 'Que tal recomeçares?',
			subtitle: 'Não encontramos essa página...',
		},
	}

const availableLocales = Object.keys(messages)
const languagesUser = navigator.languages
const localLanguageStorage = localStorage.getItem('locale')

var found = false

if(!(localLanguageStorage != null && availableLocales.includes(localLanguageStorage))){
	languagesUser.forEach(language => {
		const languagex = language
		const parts = languagex.split('-')
		language = parts[0]

		if (!found && availableLocales.includes(language)) {
			localStorage.setItem('locale', language)
			found = true
		}
	})
}

document.documentElement.lang = localStorage.getItem('locale');

// 2. Create i18n instance with options
const i18n = createI18n({
  locale: localStorage.getItem('locale') || 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages // set locale messages
  // If you need to specify other options, you can set other options
  // ...
})

export default i18n
