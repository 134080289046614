import axios from 'axios'
const state = () => {
    return {
        sections: [],
        subSections: [],
        articles: [],
    }
}

// getters
const getters = {
    sections(state) {
        return state.sections
    },
    subSections(state) {
        return state.subSections
    },
    articles(state) {
        return state.articles
    },
    checkEmpty(state) {
        return state.sections.length == 0
    },
    checkSubSectionsEmpty(state) {
        return state.subSections.length == 0
    },
    categoryById:(state) => (id) => {
        let index = state.sections.findIndex(e => e.id == id);
        if (index == -1)
            return null;
        return state.sections[index];
    },
    subSectionsById:(state) => (id) => {
        let subSections = state.subSections.filter(function (el) {
            return el.sectionId === id
        });
        return subSections
    },
}

// actions
const actions = {
    async getSections({ commit }) {
        // Make a request for a user with a given ID
        let locale = localStorage.getItem('localeStorage') || 'en';
        const response = await axios.get(window.nappmaster + '/support/' + locale + '/sections');
        const sections = response.data;

        sections.sort(compareIds)

        sections.forEach(element => {
            const customJSON = element.storageUrl;
            if(customJSON){
                element.storageUrl = JSON.parse(customJSON);
            }
        });    
        
        commit("sections", sections)
    },
    async getSubSections({ commit }, id) {
        // Make a request for a user with a given ID
        let locale = localStorage.getItem('localeStorage') || 'en';
        const response = await axios.get(window.nappmaster + '/support/section/' + id + '/subSections');
        const subSections = response.data;

        subSections.sort(compareIds)

        subSections.forEach(element => {
            const customJSON = element.storageUrl;
            if (customJSON) {
                element.storageUrl = JSON.parse(customJSON);
            }
        });

        commit("subSections", subSections)
    },
    async getAllSubSections({ commit }, id) {
        const response = await axios.get(window.nappmaster + '/all/support/subsections');
        const subSections = response.data;

        subSections.sort(compareIds)

        subSections.forEach(element => {
            const customJSON = element.storageUrl;
            if (customJSON) {
                element.storageUrl = JSON.parse(customJSON);
            }
        });

        commit("subSections", subSections)
    },
    async getArticles({ commit }, subSectionId) {
        // Make a request for a user with a given ID
        const response = await axios.get(window.nappmaster + '/support/subSection/' + subSectionId + "/articles");
        const articles = response.data; 
        commit("articles", articles)
    },
    async getAllArticles({ commit }, id) {
        const response = await axios.get(window.nappmaster + '/all/support/articles');
        const articles = response.data;

        articles.sort(compareIds)

        commit("articles", articles)
    },
}

// mutations
const mutations = {
    sections(state, value) {
        state.sections = value
    },
    subSections(state, value){
        state.subSections = value
    },
    articles(state, value) {
        state.articles = value
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}

function compareIds( a, b ) {
    if ( a.id < b.id ){
      return -1;
    }
    if ( a.id > b.id ){
      return 1;
    }
    return 0;
  }