<template>
  <div class="chat-floating-container initialize-chat-container">
    <div class="initialize-chat-header-container">
      <span class="title">We are currently unavaible</span>
      <span class="schedule">
        We are available from {{ schedule.formattedStart }} to
        {{ schedule.formattedEnd }} {{ schedule.startWeekDay }} -
        {{ schedule.endWeekDay }}
      </span>
      <span class="get-back-to-you">
        Fill your email and a message and we will get back to you
      </span>
      <div
        style="display: none"
        v-on:click="$emit('closed')"
        class="close-container"
      >
        <plus />
      </div>
    </div>
    <div class="initialize-chat-content-container">
      <input
        placeholder="Email"
        required
        v-model="info.email"
        type="email"
        class="outline-none text-sm w-full initialize-input"
      />

      <input
        placeholder="Phone (optional)"
        v-model="info.phone"
        type="phone"
        class="outline-none text-sm w-full initialize-input"
      />
      <textarea
        v-model="info.message"
        rows="4"
        placeholder="Write us a message..."
        type="text"
        class="outline-none text-sm w-full initialize-input message-input"
      />
      <button
        v-on:click="registerWithInfo"
        type="button"
        class="transition outline-none duration-200 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white w-full py-2.5 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block submit-btn"
      >
        <span class="inline-block mr-2">Send</span>
      </button>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, computed, ref, reactive } from "vue";
import { useStore } from "@/store";

import Plus from "@/components/Icons/Plus.vue";
export default defineComponent({
  components: { Plus },
  setup() {
    const store = useStore();
    const info = reactive({
      message: "",
      email: "",
      phone: "",
    });
    const schedule = computed(() => store.getters["chat/schedule"]);

    const registerWithInfo = () => {
      if (!info.email) return;
      // store.dispatch("chat/register", info);
      store.dispatch("chat/registerWithMessage", info);
    };

    onMounted(() => {});
    return {
      info,
      registerWithInfo,
      schedule,
      Plus,
    };
  },
});
</script>

<style scoped lang="scss">
@media only screen and (max-width: 600px) {
  .initialize-chat-header-container {
    border-radius: 0 !important;
  }

  .close-container {
    position: absolute;
    z-index: 1;
    right: 12px;
    cursor: pointer;
    transform: rotate(45deg);
    transition: all 0.2s;
    border-radius: 5rem;
    width: 25px;
    height: 25px;
    overflow: hidden;
    display: inline-block !important;
    padding-left: 4px;

    &:hover {
      background-color: rgba(209, 213, 219, 255);
    }

    svg {
      display: inline-block;
      fill: white;
    }
  }
}

.initialize-chat-container {
  background-color: white;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  margin-bottom: 10px;

  .initialize-chat-header-container {
    padding: 12px;
    grid-row: 1;
    grid-column: 1;
    width: 100%;
    display: flex;
    flex-flow: column;
    text-align: left;
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.3);
    background-color: #101921;
    border-radius: 6px 6px 0 0;

    span {
      color: white;
      display: inline-block;
    }

    .title {
      font-weight: bold;
      font-size: 1.5rem;
    }

    .schedule {
      font-size: 0.8rem;
      margin-top: 6px;
    }

    .get-back-to-you {
      font-size: 0.8rem;
      margin-top: 30px;
      font-weight: 700;
    }
  }

  .initialize-chat-content-container {
    grid-row: 2;
    grid-column: 1;
    padding: 8px;
    display: grid;
    gap: 14px;
    grid-template-rows: auto auto auto 10px auto 1fr;
    grid-template-columns: 1fr;
    padding-left: 12px;
    padding-right: 12px;
    text-align: left;

    .initialize-input {
      width: 93%;
      margin: auto;
      height: 40px;
      border-bottom: 1px solid lightgray;
      transition: all 0.2s;

      &:focus {
        border-bottom-color: #101921;
      }
    }

    .message-input {
      height: auto;
    }

    .submit-btn {
      grid-row: 4;
      width: 93%;
      margin: auto;
      border: 1px solid #101921;
      color: #101921;
      border-radius: 5px;
      transition: all 0.2s;

      &:hover {
        color: white;
        background-color: #101921;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
}
</style>

