import { createStore } from 'vuex'
import chat from './chat'
import auth from './auth'
import storeSetup from './storeSetup'
import support from './support'

const store = createStore({
	modules: {
		chat,
		auth,
		support,
		storeSetup
	}
})

export function useStore() {
	return store
}

export default store
