import { createRouter, createWebHistory } from 'vue-router'
import { trackRouter } from 'vue-gtag-next'
import { useStore } from '@/store'

const Home = () => import(/* webpackChunkName: "main" */'../views/Home.vue');
const Pricing = () => import(/* webpackChunkName: "main" */'../views/Pricing.vue');
const Features = () => import(/* webpackChunkName: "main" */'../views/Features.vue');
const NotFound = () => import(/* webpackChunkName: "main" */'../views/NotFound.vue');

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { title: 'NAPPS | E-commerce Mobile App', description: 'NAPPS is a mobile commerce SaaS platform that integrates directly with Shopify or Woocommerce to give you your mobile app' },
    component: Home
  },
  {
    path: '/pricing',
    name: 'Pricing',
    meta: { title: 'NAPPS | E-commerce Mobile App Pricing', description: 'We don\'t want to simply offer  a solution. We aspire to be part of your team and are committed and focused on the growth of your business.' },
    component: Pricing
  },
  {
    path: '/features',
    name: 'Features',
    meta: { title: 'NAPPS | App Features | Push Notification', description: 'Boost your ecommerce, take advantage of our features. Send push notifications and increase your conversion ratio' },
    component: Features
  },
  {
    path: '/login',
    name: 'Login',
    meta: { title: 'NAPPS | Login', description: 'Log in on Napps to access to your mobile app', redirectOnAuth: true },
    component: () => import(/* webpackChunkName: "resouces" */'../views/auth/Login.vue')
  },
  {
    path: '/startnow',
    name: 'Start Now',
    meta: { title: 'NAPPS | Create Account', description: 'Create your account on NAPPS to get your mobile app', redirectOnAuth: true },
    component: () => import(/* webpackChunkName: "resouces" */'../views/auth/Start.vue')
  },
  {
    path: '/forgot',
    name: 'Forgot Password',
    meta: { title: 'NAPPS | Forgot Password', description: 'Caso tenha esquecido de sua password para aceder ao Dashboard da NAPPS', redirectOnAuth: true },
    component: () => import(/* webpackChunkName: "resouces" */'../views/auth/Forgot.vue')
  },
  {
    path: '/reset',
    name: 'Reset Password',
    meta: { title: 'NAPPS | Reset Password', description: 'Caso tenha esquecido de sua password para aceder ao Dashboard da NAPPS', redirectOnAuth: true },
    component: () => import(/* webpackChunkName: "resouces" */'../views/auth/Reset.vue')
  },
  {
    path: '/support',
    name: 'Support',
    meta: { title: 'NAPPS | Support', description: 'Everything that you need to know to maximize the use of our features' },
    component: () => import(/* webpackChunkName: "resouces" */'../views/Support.vue')
  },
  {
    path: '/support/:id',
    name: 'Support Item',
    meta: { title: 'NAPPS | Support E-commerce Mobile App', description: 'Everything that you need to know to maximize the use of our features' },
    component: () => import(/* webpackChunkName: "resouces" */'../views/SupportItem.vue')
  },
  {
    path: '/processing',
    name: 'Data Processing',
    meta: { title: 'NAPPS | Processing Agreement', description: 'A fim de estar em conformidade com a GDPR, procuramos a data processing agreement de todos os serviços que utilizamos como processadores dentro da nossa aplicação.' },
    component: () => import(/* webpackChunkName: "main" */'../views/DataProcessing.vue')
  },
  {
    path: '/terms',
    name: 'Terms and Conditions',
    meta: { title: 'NAPPS | Terms and Conditions', description: 'Estes termos e condições criam um contrato entre si e a NAPPS' },
    component: () => import(/* webpackChunkName: "main" */'../views/Terms.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy and Policy',
    meta: { title: 'NAPPS | Privacy Policy', description: 'NAPPS has created this Privacy Policy to explain the way we collect, use, protect, share, discloser and process your personal information while operating our website.' },
    component: () => import(/* webpackChunkName: "main" */'../views/Privacy.vue')
  },
  {
    path: '/cookies',
    name: 'Cookies Policy',
    meta: { title: 'NAPPS | Cookie Policy', description: 'O Website e os serviços online da NAPPS podem utilizar Cookies' },
    component: () => import(/* webpackChunkName: "main" */'../views/CookiePolicy.vue')
  },
  {
    path: '/forgotpassword',
    name: 'Forgot password',
    meta: { title: 'NAPPS | Forgot Password', description: 'O Website e os serviços online da NAPPS podem utilizar Cookies', redirectOnAuth: true },
    component: () => import(/* webpackChunkName: "main" */'../views/CookiePolicy.vue')
  },
  {
    path: '/shop/choose',
    name: 'Choose your store',
    meta: { title: 'NAPPS | Choose your store', description: 'O Website e os serviços online da NAPPS podem utilizar Cookies' },
    component: () => import(/* webpackChunkName: "shop" */'../views/shop/setup/ChooseStore.vue')
  },
  {
    path: '/shop/configure',
    name: 'Configure your store',
    meta: { title: 'NAPPS | Configure', description: 'O Website e os serviços online da NAPPS podem utilizar Cookies' },
    component: () => import(/* webpackChunkName: "shop" */'../views/shop/setup/Configure.vue')
  },
  {
    path: '/shop/sync',
    name: 'Sync your store',
    meta: { title: 'NAPPS | Sync', description: 'O Website e os serviços online da NAPPS podem utilizar Cookies' },
    component: () => import(/* webpackChunkName: "shop" */'../views/shop/setup/Sync.vue')
  },
  {
    path: '/embed/dashboard',
    name: 'Dashboard',
    meta: { title: 'NAPPS | Dashboard', description: 'O Website e os serviços online da NAPPS podem utilizar Cookies' },
    component: () => import(/* webpackChunkName: "main" */'../views/embed/Dashboard.vue')
  },
  {
    path: '/contactus',
    name: 'Contact Us',
    meta: { title: 'NAPPS | Contact Us', description: 'O Website e os serviços online da NAPPS podem utilizar Cookies' },
    component: () => import(/* webpackChunkName: "resources" */'../views/ContactUs.vue')
  },
  {
    path: '/aboutus',
    name: 'About Us',
    meta: { title: 'NAPPS | About Us', description: 'O Website e os serviços online da NAPPS podem utilizar Cookies' },
    component: () => import(/* webpackChunkName: "resources" */'../views/AboutUs.vue')
  },
  {
    path: '/review',
    name: 'ReviewTicket',
    meta: { title: 'NAPPS | Review', description: 'O Website e os serviços online da NAPPS podem utilizar Cookies' },
    component: () => import(/* webpackChunkName: "resources" */'../views/ReviewTicket.vue')
  },
  {
    path: '/notfound',
    name: 'NotFound',
    meta: { title: 'NAPPS | Not Found', description: 'O Website e os serviços online da NAPPS podem utilizar Cookies' },
    component: NotFound
  },
  {
    path: '/press',
    name: 'Press',
    meta: { title: 'NAPPS | Contato de Mídia', description: 'O Website e os serviços online da NAPPS podem utilizar Cookies' },
    component: () => import(/* webpackChunkName: "resources" */'../views/Press.vue')
  },
  {
    path: '/team',
    name: 'Team',
    meta: { title: 'NAPPS | Team', description: 'O Website e os serviços online da NAPPS podem utilizar Cookies' },
    component: () => import(/* webpackChunkName: "resources" */'../views/Team/Team.vue')
  },
  {
    path: '/team/pedro-pontes',
    name: 'PedroPontes',
    meta: { title: 'NAPPS | Team - Pedro Pontes', description: 'O Website e os serviços online da NAPPS podem utilizar Cookies' },
    component: () => import(/* webpackChunkName: "resources" */'../views/Team/PedroPontes.vue')
  },
  {
    path: '/team/nuno-batista',
    name: 'NunoBatista',
    meta: { title: 'NAPPS | Team - Nuno Batista', description: 'O Website e os serviços online da NAPPS podem utilizar Cookies' },
    component: () => import(/* webpackChunkName: "resources" */'../views/Team/NunoBatista.vue')
  },
  {
    path: '/team/renato-mota',
    name: 'RenatoMota',
    meta: { title: 'NAPPS | Team - Renato Mota', description: 'O Website e os serviços online da NAPPS podem utilizar Cookies' },
    component: () => import(/* webpackChunkName: "resources" */'../views/Team/RenatoMota.vue')
  },
  {
    path: '/unsubscribe',
    name: 'Unsubscribe',
    meta: { title: 'NAPPS | Unsubscribe', description: 'O Website e os serviços online da NAPPS podem utilizar Cookies' },
    component: () => import(/* webpackChunkName: "resources" */'../views/Unsubscribe.vue')
  },
  {
    path: '/founders-letter',
    name: 'FoundersLetter',
    meta: { title: 'NAPPS | Founders\' Letter', description: 'O Website e os serviços online da NAPPS podem utilizar Cookies' },
    component: () => import(/* webpackChunkName: "resources" */'../views/FoundersLetter.vue')
  },
  { path: '/:pathMatch(.*)*',
    meta: { title: 'NAPPS | Not Found', description: 'O Website e os serviços online da NAPPS podem utilizar Cookies' },
    component: NotFound 
  },
]

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta) {
    document.title = to.meta.title
    const descEl = document.querySelector('head meta[name=\'description\']')
    descEl.setAttribute('content', to.meta.description)
  }

  if (to.meta.redirectOnAuth && useStore().getters['auth/isLoggedIn']) {
    next('/')
  }
  next()
})

trackRouter(router)

export default router
