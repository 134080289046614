import data from 'emoji-mart-vue-fast/data/all.json'
import { EmojiIndex } from 'emoji-mart-vue-fast/src'

const emojiIndex = new EmojiIndex(data)

function getMatch(keyword, options) {
  if (!options) {
    return false;
  };
  for (let i = 0; i < options.length; i++) {
    const emojiData = options[i];
    for (let y = 0; y < emojiData.short_names.length; y++) {
      if (emojiData.short_names[y] === keyword) {
        return emojiData;
      }
    }
  }
  return false;
}

export function formatEmojis(x) {
  let offset = 0;
  let startingIndex = 0;
  let endingIndex = -1;
  let foundLast = false;
  while (!foundLast) {
    startingIndex = x.indexOf(":", offset);
    offset = startingIndex + 1;
    endingIndex = x.indexOf(":", offset);
    offset = endingIndex + 1;
    if (endingIndex !== -1) {
      const fragment = x.substring(startingIndex + 1, endingIndex);
      const results = emojiIndex.search(fragment);
      const match = getMatch(fragment, results);
      if (match) {
        x = x.replace(
          x.substring(startingIndex, endingIndex + 1),
          match.native
        );
        offset = 0;
        startingIndex = 0;
        endingIndex = 0;
      } else {
        offset = endingIndex;
      }
    } else {
      foundLast = true;
    }
  }
  return x;
}
