<template>
  <router-view />
</template>
<script>
import { defineComponent, onMounted, computed, watch } from 'vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useStore } from '@/store'
import { connectClient, initChat } from '@/plugins/chat'
import Chat from '@/components/Chat/Main.vue'

export default defineComponent({
  components: {
    Chat
  },
  setup() {
    const store = useStore()

    const isLoggedIn = computed(() => store.getters['auth/isLoggedIn'])
    const authToken = computed(() => store.getters['auth/authToken'])

    // Get shop information if loggedin
    watch(isLoggedIn, (newValue, oldValue) => {
        if (newValue && !oldValue) {
          store.dispatch('auth/getShop')

          // Init chat for client logged in
          initChat({
            url: '://' + window.owl, // Don't fill behind the ://
            appID: 'napps-client',
            token: authToken.value,
            secure: true // If should be WSS or WS and HTTPS or HTTP
          }, 'client')

          connectClient()
        }
    })

    onMounted(() => {
      gsap.registerPlugin(ScrollTrigger)
      gsap.defaults({ ease: 'none', duration: 2 })
      // store.dispatch('chat/getSchedule')
    })

    return {}
  }
})
</script>
<style lang="scss" scoped>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
body {
  overflow-x: hidden;
  font-display:swap;
}

button,
textarea,
input,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  /* -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; */
}
</style>
