import axios from 'axios'
import { reactive, ref } from 'vue'
import * as types from './mutation-types'

const state = () => {
    return {
        shop: reactive({
            type: null,
            pluginActive: null,
            storePermissions: null
        }),
        syncHistory: reactive({}),
        syncStatus: ref(false)
    }
}

// getters
const getters = {
    shop(state) {
        return state.shop
    },
    syncStatus(state) {
      return state.syncStatus
    },
    isFirstStepDone(state) {
        return state.shop.type != null
    },
    isSecondStepDone(state) {
        return state.shop.pluginActive && state.shop.storePermissions
    },
    lastSyncMessage(state) {
        const syncIDS = Object.keys(state.syncHistory).sort((a, b) => b - a)
        if (syncIDS.length > 0) {
            const syncID = syncIDS[0]
            const sync = state.syncHistory[syncID]
            return sync.length > 0 ? sync[sync.length - 1] : null
        }
    },
    isThirdStepDone(state) {
        const syncIDS = Object.keys(state.syncHistory).sort((a, b) => b - a)
        if (syncIDS.length > 0) {
            const syncID = syncIDS[0]
            const sync = state.syncHistory[syncID]
            const allSync = ['Tax', 'Shipping', 'Categories', 'Coupon', 'Customers', 'Orders', 'Products', 'Settings']

            return allSync.every(v => sync.includes(v))
        }
    },
    isCollectionsDone(state) {
        const syncIDS = Object.keys(state.syncHistory).sort((a, b) => b - a)
        if (syncIDS.length > 0) {
            const syncID = syncIDS[0]
            const sync = state.syncHistory[syncID]

            return sync.includes('Categories')
        }
        return false
    },
    isPluginActive(state) {
        return state.shop.pluginActive
    },
    isStorePermSet(state) {
        return state.shop.storePermissions
    }
}

// mutations
const mutations = {
    [types.SETUP_SHOP_SET_TYPE](state, value) {
        state.shop.type = value
    },
    [types.SETUP_SHOP_SET_PLUGIN_ACTIVE](state, value) {
        state.shop.pluginActive = value
    },
    [types.SETUP_SHOP_SET_PERM](state, value) {
        state.shop.storePermissions = value
    },
    [types.SETUP_SHOP_SYNC_STATUS](state, value) {
        state.syncStatus = value
    },
    [types.SET_ON_SYNC_MESSAGE](state, value) {
        if (!value.syncID) {
            return
        }

        if (!(value.syncID in state.syncHistory)) {
            state.syncHistory[value.syncID] = []
        }
        state.syncHistory[value.syncID].push(value.target.replace('ProcessWordpress', '').replace('Process', ''))
    }
}

// actions
const actions = {
    newSyncMessage({ commit }, data) {
      if (data.eventType === 'sync_status') {
          commit(types.SET_ON_SYNC_MESSAGE, JSON.parse(data.payload))
      }
    },
    setShopType({ commit }, state) {
      commit(types.SETUP_SHOP_SET_TYPE, state)
    },
    setShopPerm({ commit }, state) {
      commit(types.SETUP_SHOP_SET_PERM, state)
    },
    startSync({ commit }, state) {
      axios.get(state)
          .then(response => {
              if (response.status === 200 && response.data.status) {
                  commit(types.SETUP_SHOP_SYNC_STATUS, true)
              } else {
                  commit(types.SETUP_SHOP_SYNC_STATUS, false)
              }
          })
          .catch(_error => {
              commit(types.SETUP_SHOP_SYNC_STATUS, false)
          })
    },
    setPluginActive({ commit }, state) {
      commit(types.SETUP_SHOP_SET_PLUGIN_ACTIVE, state)
    },
    checkPluginStatus({ commit }, state) {
      axios.get(state)
          .then(response => {
              if (response.status === 200) {
                  commit(types.SETUP_SHOP_SET_PLUGIN_ACTIVE, true)
              } else {
                  commit(types.SETUP_SHOP_SET_PLUGIN_ACTIVE, false)
              }
          })
          .catch(_error => {
              commit(types.SETUP_SHOP_SET_PLUGIN_ACTIVE, false)
          })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
